@import './hero';
@import './home';
@import './mobileNavbar';
@import './sidenavbar';
@import './about';
@import './blog';
@import './projects';
@import './skills';
@import './contact';

// clip-path: polygon(0% 14%, 100% 0px, 100% 84%, 0px 100%);


/*----Global-----*/
 .basic-button{
    font-size: 20px;
    color: #efd3a6;
    text-decoration: none;
    width: 150px;
    border: 2px solid #efd3a6;
    border-radius: 30px;
    line-height: 38px;
    padding: 6px;
};

.basic-button:hover{
    background-color: #dbb852;
    color: black;
    border-color: black;
}

.social-icons a:hover{
    pointer-events: none !important;
}

.social-icons a:hover{
    background-color: none;
}


body{
    margin: 0;
    padding: 0;
    background-color: #191919 ;
    color:white;
}
.App{
}


section{
    height: 100vh;
}
.row{
    width: 35%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.center{
    justify-content: center;
}
.text-center{
    text-align: center;
}
.main-title{
    color: white;
    font-size: 6.5em;
}
.main-text{
    font-size: 17px;
    color: white;
    line-height: 1.5;
}
/*---Scrool Styling ---*/

body::-webkit-scrollbar{
    width: .4vw
}
body::-webkit-scrollbar-track{
    background-color:#191919 ;
}
body::-webkit-scrollbar-thumb{
    background-color:#dbb852;
}

/*------home-----*/

.content{
    

    .divider{
        margin-top: 1vw;
    }
}

// Section backgrounds

.about-section{
    background-color: gray;
    z-index:-111;
}
.skills-section{
    
    background-color: rgb(24, 24, 24);
    z-index:-111;
}
.projects-section{
    background-color: #fafafa;
    z-index:-111;
}
.blog-section{
    background-color:gray;
    z-index:-111;
}
.contact-section{
    background-color:  #fafafa;
    z-index:-111;
}



/*----Colors:---*/

$turquoise: #024251;
$gold: #dbb852;
$dark-gray: #292634;


.button-1{
    color:$gold;
    margin: 0 10px;
    padding: 3px 12px;
    font-size: 1.3em;
    text-decoration: none;
    border: 2px solid gray;
    background: #024251;
    color: white;
    border-radius: 13%;
}

/// Media Queries ///

@media (max-width: 840px){
    .App{
        overflow-x: hidden;
    }
}