.projects-section{
    padding-left: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #292634;

    .project-heading{
        text-align: center;
        font-size: 40px;
        text-decoration: underline;
    }

    

    .project-row{
        margin-top: 15vh;
        margin-right: 10vw;

        h2{
            font-size: 2.2em;
        }
    }
    .project-card-row{
        display:flex;
        flex-direction: row;

       .project-header{
        font-size: 25px;
        background-color: #acacac;
        border: 1px solid black;
        text-align: center;
        }
        img{
            width:21vw;
            min-height: 200px;
        }
        .project-details{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: #acacac;
            align-items: center;
            padding: 5px;
            border: 1px solid black;
        }
        .project-card-1,.project-card2, .project-card-3{
            width:21vw;
            text-align: center;
            border: 1px solid white;
        }
        .project-card-2{
            margin: 0 10px;
        }
        .project-icon{
            font-size: 42px;
            align-items: center;
display: flex;
        }
        .code-links{
            display: flex;
            justify-content: center;

            .project-button{
                margin: 0 4px;
                padding: 4px 10px;
                font-size: 22px;
                text-decoration: none;
                background: #292634;
                color: white;
                border-radius: 3px;
                align-items: center;
                display: flex;
            }
        }
    }
}


// Media Query //

// Ipad screen (largest Ipad pro) //

@media ( max-width:840px ){
    
    .projects-section{

        padding-left: 0;
        padding-right: 10vw;
        .project-row{
            margin-top: 0vh;
            margin-right: 0vw;
            display: block;

            .project-card-row{
                display: flex;
                flex-direction: column;

                .project-header{
                    margin-bottom: 0;
                }
                .project-card-1,.project-card-2,.project-card-3{
                    width: 45vw;

                    img{
                        width:45vw;
                        min-height: 175px;
                    }
                }
                .project-card-2{
                    margin: 0;
                }
            }
        }
    
    }

}
    
    // Mobile Screens //
    
    @media only screen and (max-width:480px){
    
        .projects-section{
            
            .project-row{
                .project-card-row{
                    
                    .project-card-1,
                    .project-card-2,
                    .project-card-3{
                        width: 65vw;
                        img{
                            min-height:0;
                            width: 65vw;
                        }
                    }
                    .project-card-2{
                        margin: 0;
                    }
                    .project-header{
                        margin-bottom: 0;
                    }
                }
            }
        }
        
    }


     // Mobile X small Screens //
    
    // Optimised for Note 10+ 5G  (360 x 718)//

    @media (max-width:360px){

        .projects-section{
            padding-right: 0;
            .project-row{
                .project-heading{
                    margin-bottom: 0;
                    margin-top: 1vh;
                }
                .project-card-row{
                    .project-icon{
                        font-size: 25px;
                    }
                    .project-card-1,
                    .project-card-2,
                    .project-card-3{
                        width: 65vw;
                        img{
                            min-height:0;
                            width: 65vw;
                        }
                    }
                    .project-card-2{
                        margin: 0;
                    }
                    .project-header{
                        font-size: 22px;
                        margin-bottom: 0;
                    }
                    .code-links{
                        .project-button{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }