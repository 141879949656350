
.skills-section{
    
    display: flex;
    flex-direction: row;
    padding-left: 10vw;
    padding-top: 8vh;

    a{
        text-decoration: none;
        color: #dbb852;
    }
    .main-title{
        font-size: 4.3em;
        margin: .75vh 0;
    }
    .main-text{
        font-size: 18px;
    }
    .main-text-1{
        margin-top: 4vh;
        line-height: 1.7;
    }
    .progress-group{
        padding-bottom: 10px;

        span{
            padding-right: 10px;
            font-size: 19px;
        }
        progress{
            min-width:34vw;
            max-width:34vw;
        }
    }
    .progress-row{
        padding-right: 19vw;
        width:35%;
    }

    .timeline-row{
        width: 70%;
        margin-top: 20vh;
        #card-2{
            margin-top: 17vh;
        }
        #card-3{
            margin-top: 34vh;
        }

        .timeline-card{
            z-index: 1111;
            text-align: center;
            width: 20vw;
            height: 125px;
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 10px;
            background: inherit;
            position: absolute;
            overflow: hidden;
            
            .title{
                font-size: 23px;
                margin: 5px 0 28px 0;
                z-index: 1111;
            }
            .date{
                margin:0;
                color:#a6a6a6;
                z-index: 1111;
            }
            .description{
                color:#a6a6a6;
                z-index: 1111;
            }
        }
        .timeline-card:before{
            content: "";
            position: absolute;
            left: -25px;
            top: -25px;
            bottom: 0;
            right: 0;
            box-shadow: inset 0 0 0 3000px rgb(47, 47, 47);
            filter: blur(10px);

        }
    }
    .skill-bar{
        // Target Progress Bar //
        progress {
            border: none;
            background: #2f2f2f;
            border-radius: 15px;
            height: 8px;
        }
        // Cross compatibility //
        progress::-moz-progress-bar {
            background: #dbb852;
            border-radius: 10px;
        }
        
        progress::-webkit-progress-value {
            background: #dbb852;
            border-radius: 10px;
        }
        
        progress::-webkit-progress-bar {
            background: #2f2f2f;
            border-radius: 10px;
        }
    }
}

// Media Query //

// Ipad screen (largest Ipad pro) //

@media ( max-width:840px ){
    
    .skills-section{

        padding-top: 3vh;
        .progress-row{
            width:50%;
            padding-right: 3vw;
            .skill-bar{
                margin-top: 4vh;
                .progress-group{
                    span{
                        display: block;
                    }
                }
            }
        }

        .timeline-row{

            margin-top: 19vh;
            #card-2{
                margin-top: 22vh;
            }

            #card-3{
                margin-top: 44vh;
            }

            .timeline-card{
                width: 20vw;
                height: 125px;
                padding: 33px;

                .title{
                    font-size: 23px;
                    margin: -25px 0 28px 0;
                }
                .date{
                    margin: -20px 0 0 0;
                }
                .description{
                    padding-top: 10px;
                }
            }
        }
    
    }

}
    
    // Mobile Screens //
    
    @media (max-width:480px){
    
        .skills-section{
            display: flex;
            flex-direction: column;
            padding-left: 10vw;
            padding-top: 5vh;
            .progress-row{
                
                width: 100%;
                .main-title{
                    font-size: 2.5em;
                    display: inline;
                    margin-bottom: 0;
                }
                .main-text{
                    font-size: 16px;
                    padding-right: 7vw;
                    padding-top: 0;
                    margin-top: 2vh;
                }
                .progress-group{
                    display: flex;
                    flex-direction: column;
                    progress{
                        min-width: 80vw;
                        max-width: 80vw;
                    }
                }
            }
            .timeline-row{
                margin-top: 3vh;
                .timeline-card{
                    padding: 20px;
                    width: 20vw;
                    height: 220px;

                    .title{
                        font-size: 18px;
                        margin: -9px 0 28px 0;
                    }
                    .description{
                        font-size: 14px;
                        line-height: 1.25;
                    }
                }
                #card-1{
                    margin-left: -7vw;
                }
                #card-2{
                    margin-top: 0;
                    margin-left: 26vw;
                }
                #card-3{
                    margin-top: 0;
                    margin-left: 59vw;
                }
            }

        }
        
    }


     // Mobile X small Screens //
    
    // Optimised for Note 10+ 5G  (360 x 718)//

    @media (max-width:360px){

        .skills-section{
            .timeline-row{
                display: none;
            }
        }
    }