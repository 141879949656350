.about-section{
    .about-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .box-wrapper-1{
            height: 66vh;
            width: 85vw;
            background-color: #000;
            position: absolute;
            z-index: 1;
            left: 6vw;
            top: 67.5vw;
        }
        .box-wrapper-2{
            height: 66vh;
            width: 85vw;
            left: 8vw;
            top: 137vh;
            background-color: rgba(219, 184, 82, 0.9);
            position: absolute;
            z-index: 11;
        }
        
        .about-text{
            padding-top: 17vh;
            z-index: 111;
            padding-left: 12vw;
            width: 30%;
            h1{
                margin: 0 0 5vh 0;
                padding: 0;
            }
            #about-image-mob{
                display:none;
            }

            .main-text{
                font-size: 20px;
                color: #292634;
                line-height: 1.5;
                margin-bottom: 6vh
            }    
        }
        .about-image{
            padding-top: 28vh;
            padding-right: 26vw;
            z-index: 111;
        }
        .profile-pic{
            width: 300px
        }
        .main-text{
            
        }
    }
    .tech-row{
        
        display:flex;
        flex-direction: row;
        padding-right: 5vw;
        padding-top: 5vh;
        z-index: 1111;
        justify-content: space-around;

        .heading{
            display: flex;
            flex-direction: column;
            margin-right: 35px;
            
            h2{
            font-size: 27px;
            margin: 0;
            padding: 0 10px;
            color: #292634;
            }
        }
        .current-tech-row{
            z-index: 111;
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width: 48%;
                height: max-content;
            }
        }
        .other-tech-row{
            z-index: 111;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .tech-icon{
            padding: 10px 12px;

            img{
                width:85px;
            }
            p{
                padding: 0;
                margin: 0;
                text-align: center;
            }
        }
    }
}


// Media Query //

// Ipad screen (largest Ipad pro) //

@media ( max-width:840px ){
    
    .about-section{

        .about-row{
            .box-wrapper-1{

                height: 66vh;
                width: 70vw;
                position: absolute;
                z-index: 1;
                left: 7vw;
                top: 130vh;
            }
            .box-wrapper-2{
                top: 132vh;
                width: 70vw;
                left: 10vw;
            }

            .about-text{
                width: 100%;
                padding-top: 11vh;
                z-index: 111;
                padding-left: 17vw;

                .main-title{
                    font-size:5em;
                    margin: 6vh 0 1vh 0;
                    padding: 0;
                }

                .main-text{
                    font-size: 20px;
                    color: #292634;
                    line-height: 1.5;
                    margin-top: 0;
                    margin-bottom: 2vh;
                    padding-top: 0vh;
                }
            }

            .about-image{
                padding-top: 20vh;
                padding-right: 0vw;
                z-index: 111;
                margin-right: 25vw;
                margin-bottom: 0;
                height: 150px;

                .profile-pic{
                    width: 150px;
                }
            }
        }

        .tech-row{
            display: flex;
            flex-direction: column;
            padding-right: 10vw;
            padding-top: 0vh;
            z-index: 1111;
            justify-content: center;

            .current-tech-row{
                z-index: 111;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-right: 24vw;
                margin-bottom: 2vh;

                img{
                    width: 200px;
                }

            }

            .other-tech-row{
                z-index: 111;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: -1vw;
                justify-content: center;

                .tech-icon{
                    img{
                        width: 85px;
                    }
                }
            }
        }

    }
    
    }
    
    // Mobile Screens //
    
    @media (min-width:360px) and (max-width:480px){
    
        .about-section{
  
            .about-row{

                #about-image{
                    display: none;
                }

                .box-wrapper-1{
                    height: 96vh;
                    width: 96vw;
                    left: 2vw;
                    top: 128vh;
                }
                .box-wrapper-2{

                    top: 130vh;
                    width: 90vw;
                    left: 5vw;
                    height: 92vh;
                }
                .about-text{
                    padding-top: 0;
                    padding-left: 14vw;

                    #about-image-mob{
                        display: block;
                        margin-left: 15vw;
                    }

                    .main-title{
                        font-size: 3em;
                        margin: 11vh 0 1vh 0;
                    }
                    .main-text{
                        font-size: 16px;
                        padding-top: 4vh;
                        padding-right: 15vw;
                    }
                }
                .about-image{
                    padding-top: 1vh;
                    padding-right: 0;
                    margin-right: 15vw;
                    .profile-pic{
                        width: 150px;
                    }
                }
            }
                .tech-row{
                    margin-right: 0vw;
                    margin-left: 5vw;
                    .current-tech-row{
                        margin-bottom: 4vh;
                        margin-top: 4vh;

                        .heading{
                            
                            h2{
                                font-size: 22px;
                            }
                        }
                        img{
                            width: 150px;
                        }
                    }
                    .other-tech-row{
                        margin-right: 3vw ;
                        .heading{
                            margin-right: 10px;
                            h2{
                                font-size:22px ;
                                padding: 0;
                            }
                        }

                        .tech-icon{
                            padding: 10px 6px;
                            img{
                                width: 60px;
                            }
                        }
                    }   
                }
        }
        
        }


    // Mobile X small Screens //
    
    // Optimised for Note 10+ 5G  (360 x 718)//

    @media (max-width:359px){
    
        .about-section{
  
            .about-row{

                #about-image{
                    display: none;
                }

                .box-wrapper-1{
                    height: 96vh;
                    width: 96vw;
                    left: 2vw;
                    top: 128vh;
                }
                .box-wrapper-2{

                    top: 130vh;
                    width: 90vw;
                    left: 5vw;
                    height: 92vh;
                }
                .about-text{
                    padding-top: 0;
                    padding-left: 14vw;

                    #about-image-mob{
                        display: block;
                        margin-left: 15vw;
                    }

                    .main-title{
                        font-size: 3em;
                        margin: 5vh 0 1vh 0;
                    }
                    .main-text{
                        font-size: 14px;
                        padding-top: 0vh;
                        padding-right: 15vw;
                    }
                }
                .about-image{
                    padding-top: 1vh;
                    padding-right: 0;
                    margin-right: 15vw;
                    .profile-pic{
                        width: 125px;
                    }
                }
            }
                .tech-row{
                    margin-right: 0vw;
                    margin-left: 5vw;
                    .current-tech-row{
                        margin-bottom: 4vh;
                        margin-top: 4vh;
                        margin-right: 4vw;
                        .heading{
                            
                            h2{
                                font-size: 18px;
                            }
                        }
                        img{
                            width: 150px;
                        }
                    }
                    .other-tech-row{
                        margin-right: -4vw ;
                        .heading{
                            margin-right: 10px;
                            h2{
                                font-size:18px ;
                                padding: 0;
                            }
                        }

                        .tech-icon{
                            padding: 10px 6px;
                            img{
                                width: 40px;
                            }
                            p{
                                font-size: 13px;
                            }
                        }
                    }   
                }
        }
        
        }