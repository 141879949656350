
.hero{

    height: 100vh;
    padding: 10vw 5vw 0 0;

    .basic-button{
        margin-left: 30px;
        color: white;
        border-color: white;
    }
    .hero-title-row{
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .hero-name{
            font-size: 7em;
            margin: 0 10px 0;
            color: #fafafa;
        }
        .hero-name:hover{
          color: #dbb852;
        }
        .hero-title{
            font-size: 1.5em;
            margin: 10px;
            font-weight: 600;
            color:gray;
        }
    }

    // Social Icons //

    .social-icons{
        display: flex;
        flex-direction: row;
        li{
            padding: 6px;
            list-style: none;
            cursor: pointer;
        }

        a{
            text-decoration: none;
            font-size: 35px;
            color: white ;
            cursor: pointer;
            padding: 2px;
        }
        svg{
          cursor: pointer;
        }
        svg:hover{
            
            color: #dbb852;
        }
    }

    .scroll-down{
        justify-content: flex-end;
        margin-left: 83vw;
        display: flex;
        
        a{
            text-decoration: none;
            color: white;
            padding-right: 30px;
            margin-top: 15vh;
            margin-bottom: 6vh;
            font-size: 24px;
            align-items: center;
            text-align: center;
            
        }

        span{
            display: block;
            font-size: 30px;
        }

    }

    .scroll-down a:hover{
      color: #dbb852;
    }
// Background //

@mixin sp-layout {
    @media screen and (max-width: 750px) {
      @content;
    }
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
     background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    overflow: hidden;
  }
  
  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
  .stars {
    z-index: -111111;
    position: fixed;
    top: 0;
    left: 0;
    width: 120%;
    height: 120%;
    transform: rotate(-45deg);
  }
  
  .star {
    $star-count: 50;
    --star-color: var(--primary-color);
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
  
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
    
    @include sp-layout {
      // For mobile performance, tail-fade animation will be removed QAQ
      animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }
  
    @for $i from 1 through $star-count {
      &:nth-child(#{$i}) {
        --star-tail-length: #{random_range(500em, 750em) / 100};
        --top-offset: #{random_range(0vh, 10000vh) / 100};
        --fall-duration: #{random_range(6000, 12000s) / 1000};
        --fall-delay: #{random_range(0, 10000s) / 1000};
      }
    }
  
    &::before, &::after {
      position: absolute;
      content: '';
      top: 0;
      left: calc(var(--star-width) / -2);
      width: var(--star-width);
      height: 100%;
      background: linear-gradient(45deg, transparent, currentColor, transparent);
      border-radius: inherit;
      animation: blink 2s linear infinite;
    }
  
    &::before {
      transform: rotate(45deg);
    }
  
    &::after {
      transform: rotate(-45deg);
    }
  }
  
  @keyframes fall {
    to {
      transform: translate3d(-30em, 0, 0);
    }
  }
  
  @keyframes tail-fade {
    0%, 50% {
      width: var(--star-tail-length);
      opacity: 1;
    }
  
    70%, 80% {
      width: 0;
      opacity: 0.4;
    }
  
    100% {
      width: 0;
      opacity: 0;
    }
  }
  
  @keyframes blink {
    50% {
      opacity: 0.6;
    }
  }


}

// Media Query //

// Ipad screen (largest Ipad pro) //

@media(max-width:840px){

  .hero{
    padding: 25vw 15vw 0 0;
    .hero-title-row{
      .hero-name{
        font-size: 5em;
      }
    }
    .scroll-down{
      padding-top: 5vh;
    }
  }
  
  }
  
  // Mobile Screens //
  
  @media(max-width:480px){
  
      .hero{
          
        padding: 50vw 5vw 0 0;

        .hero-title-row{

          padding-left: 12vw;
          .hero-name{
            font-size: 3.5em;
          }
          .hero-title{
            padding-top: 2vh;
          }

          .social-icons{
            padding-right: 10vw;
            padding-top: 1vh;
            a{
              font-size: 40px;
            }
            }
          }
      
        .scroll-down{
          display: none;
        }

        .contact-button{

          .basic-button{
            padding: 12px;
            font-size: 22px;
            margin-left: 0;
          }
        }
      }
      }

      @media(max-width: 360px){
        .hero{
          .hero-title-row{
            .hero-name{
              font-size:3em;
            }
          }
        }
      }