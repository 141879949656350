.contact-section{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .contact-form{
        
        display: flex;
        justify-content: center;
        align-items: center;

        form{
            text-align: center;
            width: 85%;
            max-width: 40vw;
            background: #292634;
            padding: 30px 40px;
            box-sizing: border-box;
            box-shadow: 0 0 20px #000000b3; 
            margin-top: 20vh;

            h3{
                font-weight: 100;
                font-size: 2em;
                text-decoration: underline;
                padding-top: 30px;
                margin-bottom: 80px;
            }

            h5{
                font-size: 1.5em;
                font-weight: 200;
            }

            button{
                margin-top: 15px;
                text-align: center;
                font-size: 19px;
                color: white;
                text-decoration: none;
                width: 100px;
                border: 2px solid white;
                line-height: 38px;
                outline: none;
                background-color: transparent;
                cursor: pointer;
            }

            button:hover{
                background-color: #dbb852;
            }
        .contact-divider{
            display: flex;
            flex-direction: row;
            .contact-row{
                border: 1px solid whitesmoke;
                margin: 10px;
                padding: 12px 18px;

                label{
                    display: inline-block;
                    text-align: left;
                    color: white;
                    text-transform: uppercase;
                    font-size: 15px;
                    }
                input, textarea{
                    width: 100%;
                    border: none;
                    background: none;
                    outline: none;
                    font-size: 18px;
                    margin-top: 6px;
                    color: white;
                    font-weight: 400;
                    }
                }
            }
        }
    
        }
        .footer{
            background-color: #292634;
            color: white;
            height: 15vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .social-links{
                text-align: center;
                display: flex;
                align-items: center;
                a{
                    text-decoration: none;
                    font-size: 25px;
                    color: white;
                    padding: 6px 10px;
                    margin: 8px;
                    border: 1px solid white;
                }
            }
        }



    }


    // Media Query //

// Ipad screen (largest Ipad pro) //

@media ( max-width:840px ){
    
    .contact-section{

        .contact-form{

            form{
                max-width: 72vw;
            }
        }
    }

}
    
    // Mobile Screens //
    
    @media (max-width:480px){
    
        .contact-section{
            
            .contact-form{
                form{
                    margin-top: 2vh;
                    max-width: 92vw;
                    padding: 30px;
                    height: 80vh;
                    h3{
                        padding-top: 0;
                        margin-bottom: 20px;
                    }
                    .contact-divider{
                        flex-direction: column;
                    }
                }
            }
        }
        
    }


    
     // Mobile X small Screens //
    
    // Optimised for Note 10+ 5G  (360 x 718)//

    @media (max-width:360px){

        .contact-section{
            
            .contact-form{
                form{
                    margin-top: 2vh;
                    max-width: 92vw;
                    padding: 20px 30px;
                    height: 80vh;
                    h3{
                        font-size: 22px;
                        padding-top: 0;
                        margin-bottom: 20px;
                        margin-top: 0;
                    }
                    h5{
                        font-size: 16px;
                    }
                    .contact-divider{
                        flex-direction: column;
                    }
                    .button{
                        margin-top: 10px;
                        padding: 0;
                        width: 90px;
                    }
                }
            }
        }
    }