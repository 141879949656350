
.sidenav{
    height: 100vh;
    width: 3vw;
    padding-top: 0;
    padding-left: 5px;
    position: fixed;
    text-align: center;
    z-index: 1111111;
    display: block;
    a{
        text-decoration: none;
    }
    //Border Starts Here//
    ul{
        background-color: #191919;
        margin-top: 25vh;
        padding: 5px;
        border-radius: 5px;
    }
    li{
        padding: 8px 6px;
        list-style: none;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }

    .sidenav-icons{
        svg{
            font-size:30px;
            color: white;
        }
        p{
            position: absolute;
            color: white;
            font-size: 24px;
            padding: 0;
            left: 3.5vw;
            background: #000;
            padding: 8px;
            border-radius: 10px;
            transition: ease-in-out 1s;
            opacity: 0;
        }
    }
    .logo{
        margin: 0;
        color: white;
    }
}




/*-----Animations---*/
.sidenav{
    li:hover{ 
        svg{
            color: #dbb852;
        }
        p{
        opacity: 1;
        }

            }
}

// Media Query //

// Ipad screen (largest Ipad pro) //

@media ( max-width:840px ){

    .sidenav{

        .sidenav-icons{
            padding-right: 45px;

        p{
            left:7vw;
        } 
        }
    
    }
    
    }
    
    // Mobile Screens //
    
    @media only screen and (max-width:480px){
    
        .sidenav{
            display: none;
        }
        
        }